import {
    AppBar,
    Button,
    CircularProgress,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import api from '../../../../api';

const NewSignerAppBar = ({
    tags,
    loading,
    canvasHeight,
    currentTagIndex,
    setCurrentTagIndex,
    setIsSubmitSuccess
}) => {
    const handleClickStartOrNext = () => {
        if (currentTagIndex === -1 || tags[currentTagIndex].inputData) {
            setCurrentTagIndex((prev) => prev + 1);
        }
    };

    useEffect(() => {
        if (tags && tags[currentTagIndex]) {
            window.scrollTo({
                top:
                    canvasHeight * tags[currentTagIndex].page +
                    tags[currentTagIndex].y -
                    100,
                behavior: 'smooth'
            });
        }
    }, [currentTagIndex]);

    // when click Submit, send the documentId, signerId and tags to the backend, backend will generate a new file with all the tag input data
    const handleClickSubmit = async () => {
        const res = await api.post('/generateSignedDocument', {
            documentId: tags[0].DocumentId,
            signerId: tags[0].SignerId,
            tags: tags
        });

        if (res.status === 200) {
            setIsSubmitSuccess(true);
        } else {
            console.error('Failed to generate signed document');
        }
    };

    const StartOrNextButton = () => {
        return (
            <Button
                style={{
                    backgroundColor: '#FFD700',
                    color: '#000000'
                }}
                variant="contained"
                onClick={handleClickStartOrNext}
            >
                {currentTagIndex === -1 ? 'Start' : 'Next'}
            </Button>
        );
    };

    const SubmitButton = () => {
        return (
            <Tooltip title="By clicking submit, a signed copy of the document will be emailed to you and the sender.">
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    style={{
                        backgroundColor: '#FFD700',
                        color: '#000000'
                    }}
                    variant="contained"
                    onClick={handleClickSubmit}
                    loadingPosition="end"
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {loading ? '' : 'Submit'}
                </LoadingButton>
            </Tooltip>
        );
    };

    // check if all required fields are signed; if it is not required, can be skipped
    const [allRequiredCompleted, setAllRequiredCompleted] = useState(false);

    useEffect(() => {
        const allRequiredCompleted = tags.every(
            (tag) => tag.inputData !== null
        );

        setAllRequiredCompleted(allRequiredCompleted);
    }, [tags]);

    return (
        <AppBar position="sticky" color="primary">
            <Toolbar>
                <Typography style={{ flexGrow: 0.7, textAlign: 'left' }}>
                    {allRequiredCompleted
                        ? "You have successfully signed. Click the 'Submit' button to send the document."
                        : currentTagIndex === -1
                          ? "Please review and sign the document below. Click the 'Start' button to begin."
                          : "Click the 'Next' button to proceed to the next section."}
                </Typography>
                {allRequiredCompleted ? (
                    <SubmitButton />
                ) : (
                    <StartOrNextButton />
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NewSignerAppBar;
