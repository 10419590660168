import { Button, Grid } from '@mui/material';
import SignTag from './SignTag';
import SignDateTag from './SignDateTag';
import TextfieldTag from './TextfieldTag';
import { TAG_ACTIONS } from '../EsignInterface';

interface EsignTagBarProps {
    handleClickFinishAndSend: () => void;
}

const EsignTagBar = ({ handleClickFinishAndSend }: EsignTagBarProps) => {
    return (
        <Grid
            container
            item
            xs={12}
            style={{
                position: 'sticky',
                top: 60,
                zIndex: 1000
            }}
        >
            <Grid container item xs={8} alignItems="center" spacing={2} gap={2}>
                <SignTag action={TAG_ACTIONS.VIEW} />
                <TextfieldTag action={TAG_ACTIONS.VIEW} />
                <SignDateTag action={TAG_ACTIONS.VIEW} />
            </Grid>

            <Grid container item xs={4} justifyContent="flex-end">
                <Button
                    variant="contained"
                    sx={{
                        marginBottom: 2
                    }}
                    onClick={handleClickFinishAndSend}
                >
                    Finish and Send
                </Button>
            </Grid>
        </Grid>
    );
};

export default EsignTagBar;
