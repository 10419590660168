import React, { useState } from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
    Grid,
    Box,
    Button
} from '@mui/material';
import AddSigner from './components/AddSigner';
import PageWrapper from '../global/PageWrapper';
import { withSnackbar } from '../global/WrappingSnackbar';
import UploadAndTag from './components/UploadAndTag';
import { Signer } from './EsignInterface';

const EsignPage = ({ showSnackbar }) => {
    const [activeStep, setActiveStep] = useState(0);

    const [signer, setSigner] = useState<Signer | null>(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        {
            label: 'Add Signer',
            content: (
                <AddSigner
                    signer={signer}
                    setSigner={setSigner}
                    handleNext={handleNext}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            label: 'Upload Document',
            content: (
                <UploadAndTag signer={signer} showSnackbar={showSnackbar} />
            )
        }
    ];

    return (
        <PageWrapper>
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    optional={
                                        index === steps.length - 1 ? (
                                            <Typography variant="caption">
                                                Last step
                                            </Typography>
                                        ) : null
                                    }
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    {step.content}
                                    <Box sx={{ mb: 2 }}>
                                        {index !== 0 && (
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        )}
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default withSnackbar(EsignPage);
