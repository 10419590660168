import api from '../../../../api';

////////////////////////// this will be improved to only call one api endpoint //////////////////////////
const HandleEmailSigner = async ({
    customerSignUrl,
    documentId,
    tags,
    newStatus,
    showSnackbar
}) => {
    try {
        // create all added tags
        const createTagRes = await api.post('/createTags', {
            tags: tags
        });

        if (createTagRes.status === 200) {
            // Tags created successfully, proceed to email signer
            const emailRes = await api.post('/emailSigner', {
                documentId: documentId,
                url: customerSignUrl,
                newStatus: newStatus
            });

            if (emailRes.status === 200) {
                showSnackbar(
                    'Email sent successfully!',
                    'The page will now refresh.',
                    'success'
                );

                // reload the page after 2 seconds
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                showSnackbar(
                    'Email sending failed',
                    'Please try again later.',
                    'error'
                );
            }
        } else {
            showSnackbar(
                'Tag creation failed',
                'Please try again later.',
                'error'
            );
        }
    } catch (error) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    }
};

export default HandleEmailSigner;
