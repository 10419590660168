import { useEffect, useRef, useState } from 'react';
import { getDocument } from 'pdfjs-dist';
import PageWrapper from '../global/PageWrapper';
import { Box, Grid } from '@mui/material';
import { GetLinkedTags } from './logic/HandleTag';
import { GetDocumentUrl } from './logic/HandleDocument';
import NewSignerAppBar from './components/NewSignerAppBar';
import { Tag, TAG_ACTIONS } from './EsignInterface';
import ResizableTag from './components/ResizableTag';
import FileLoadingPage from './components/FileLoadingPage';
import CustomerSubmitSuccessPage from './components/CustomerSubmitSuccessPage';

const CustomerSignPage = () => {
    const [fileUrl, setFileUrl] = useState(null);
    const [status, setStatus] = useState(null);
    const canvasRefs = useRef([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [sortedTags, setSortedTags] = useState<Tag[]>([]);
    const [currentTagIndex, setCurrentTagIndex] = useState(-1);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const documentId = urlParams.get('document');
    const signerId = urlParams.get('signer');

    // get the file url
    const getFileUrl = async () => {
        const { signedUrl, docStatus } = await GetDocumentUrl(documentId);
        setFileUrl(signedUrl);
        setStatus(docStatus);
    };

    // get all linked Tags
    const getTags = async () => {
        const linkedTags = await GetLinkedTags({
            DocumentId: documentId,
            SignerId: signerId
        });

        // sort tags by page number, then y, then x
        const sorted = linkedTags.sort((a, b) => {
            if (a.page === b.page) {
                if (a.y === b.y) {
                    return a.x - b.x;
                }
                return a.y - b.y;
            }
            return a.page - b.page;
        });

        setSortedTags(sorted);
    };

    useEffect(() => {
        getFileUrl();
        getTags();
    }, [documentId, signerId]);

    const [canvasReady, setCanvasReady] = useState(false);

    useEffect(() => {
        if (fileUrl) {
            const loadingTask = getDocument(fileUrl);

            loadingTask.promise.then((pdf) => {
                const numPages = pdf.numPages;
                setNumberOfPages(numPages);

                for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                    pdf.getPage(pageNum).then((page) => {
                        const scale = 2;
                        const viewport = page.getViewport({ scale });

                        const canvas = canvasRefs.current[pageNum - 1];

                        if (canvas) {
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;
                            const renderContext = {
                                canvasContext: canvas.getContext('2d'),
                                viewport: viewport
                            };
                            page.render(renderContext).promise.then(() => {
                                setCanvasReady(true);
                            });
                        } else {
                            console.log(
                                'Canvas is not defined at index:',
                                pageNum - 1
                            );
                        }
                    });
                }

                setPdfLoaded(true);
            });
        }
    }, [fileUrl]);

    const canvasHeight = canvasRefs.current[0]?.height;

    if (isSubmitSuccess || status === 'signed') {
        return <CustomerSubmitSuccessPage />;
    }

    if (!pdfLoaded) {
        return <FileLoadingPage />;
    }

    return (
        <PageWrapper notVisible={true}>
            <Grid container spacing={2}>
                <NewSignerAppBar
                    currentTagIndex={currentTagIndex}
                    setCurrentTagIndex={setCurrentTagIndex}
                    tags={sortedTags}
                    loading={false}
                    canvasHeight={canvasHeight}
                    setIsSubmitSuccess={setIsSubmitSuccess}
                />
                {/* // display all pdf canvas    */}
                {Array.from({ length: numberOfPages }, (_, pageNo) => (
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <Box
                            className="pdf-container"
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: 'auto'
                            }}
                        >
                            <canvas
                                key={pageNo}
                                ref={(el) => (canvasRefs.current[pageNo] = el)}
                                className="pdf-canvas"
                            ></canvas>
                            {canvasReady &&
                                sortedTags.map((tag, index) => {
                                    if (tag.page === pageNo) {
                                        // get the current canvas size
                                        const rect =
                                            canvasRefs.current[
                                                pageNo
                                            ].getBoundingClientRect();

                                        // Calculate the scaling factor based on the current and original canvas sizes.
                                        const scaleX =
                                            rect.width /
                                            tag.originalCanvasWidth;
                                        const scaleY =
                                            rect.height /
                                            tag.originalCanvasHeight;

                                        // Adjust position and size.
                                        const adjustedPosition = {
                                            x: tag.x * scaleX + rect.left,
                                            y: tag.y * scaleY,
                                            width: tag.width * scaleX,
                                            height: tag.height * scaleY
                                        };

                                        return (
                                            <ResizableTag
                                                key={index}
                                                index={index}
                                                action={TAG_ACTIONS.FILL}
                                                tag={sortedTags[index]}
                                                tags={sortedTags}
                                                setTags={setSortedTags}
                                                width={adjustedPosition.width}
                                                height={adjustedPosition.height}
                                                x={adjustedPosition.x}
                                                y={adjustedPosition.y}
                                                currentTagIndex={
                                                    currentTagIndex
                                                }
                                                setCurrentTagIndex={
                                                    setCurrentTagIndex
                                                }
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </PageWrapper>
    );
};

export default CustomerSignPage;
