import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useState } from 'react';
import Tabs from '../../global/Tabs';
import SelectSignature from './SelectSignature';
import DrawSignature from './DrawSignature';
import { Tag } from '../EsignInterface';
import { LoadingButton } from '@mui/lab';

interface AddSignatureDialogProps {
    openSignatureDialog: boolean;
    setOpenSignatureDialog: React.Dispatch<React.SetStateAction<boolean>>;
    tag: Tag;
    customerName: string;
    setSignatureDataUrl: React.Dispatch<React.SetStateAction<string>>;
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const NewAddSignatureDialog = ({
    openSignatureDialog,
    setOpenSignatureDialog,
    tag,
    customerName,
    setSignatureDataUrl,
    setTags
}: AddSignatureDialogProps) => {
    const [tempSignatureDataUrl, setTempSignatureDataUrl] = useState('');

    // two tabs allow customer to select signature style or draw signature
    const AddSignatureTabs = [
        {
            id: 0,
            title: 'Draw Signature',
            content: (
                <DrawSignature
                    setTempSignatureDataUrl={setTempSignatureDataUrl}
                />
            )
        },
        {
            id: 1,
            title: 'Select Signature',
            content: (
                <SelectSignature
                    name={customerName}
                    setTempSignatureDataUrl={setTempSignatureDataUrl}
                />
            )
        }
    ];

    const handleAddSignature = () => {
        localStorage.setItem(tag.SignerId.toString(), tempSignatureDataUrl);
        setSignatureDataUrl(tempSignatureDataUrl);

        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.id === tag.id) {
                    return { ...t, inputData: tempSignatureDataUrl };
                }
                return t;
            });
        });

        setOpenSignatureDialog(false);
    };

    const onClose = () => {
        setOpenSignatureDialog(false);
    };

    return (
        <Dialog open={openSignatureDialog} onClose={onClose} fullWidth>
            <DialogTitle>{'Add Your Signature'}</DialogTitle>
            <DialogContent>
                <Tabs tabContent={AddSignatureTabs} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        color: 'main.primary'
                    }}
                    variant="contained"
                    autoFocus
                    onClick={handleAddSignature}
                >
                    Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default NewAddSignatureDialog;
