import React from 'react';
import { IconButton, Typography } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Tag, TAG_ACTIONS } from '../EsignInterface';
import CloseIcon from '@mui/icons-material/Close';
import TagWrapper from './TagWrapper';
import dayjs from 'dayjs';

interface SignDateTagProps {
    action: string;
    tag?: any;
    fontSize?: string;
    iconSize?: string;
    hasLabel?: boolean;
    removeTag?: () => void;
    setTags?: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const SignDateTag = ({
    action,
    tag,
    fontSize,
    iconSize,
    hasLabel,
    removeTag,
    setTags
}: SignDateTagProps) => {
    const isFillable = action === TAG_ACTIONS.FILL;
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;
    const shouldDisplayLabel =
        isViewOnly || isFillable || (isResizable && hasLabel);

    const dragStartHandler = (event) => {
        const rect = event.target.getBoundingClientRect();
        // calculate the offset of the mouse cursor from the top left corner

        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        event.dataTransfer.setData('type', 'dateSigned');
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());
    };

    const [dateSigned, setDateSigned] = React.useState(null);

    const handleOnClickTag = (tag: Tag) => {
        const today = dayjs().format('DD/MM/YYYY');
        setDateSigned(today);

        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.id === tag.id) {
                    return { ...t, inputData: today };
                }
                return t;
            });
        });
    };

    return (
        <TagWrapper
            isViewOnly={isViewOnly}
            isResizable={isResizable}
            dragStartHandler={dragStartHandler}
            onClick={isFillable ? () => handleOnClickTag(tag) : undefined}
        >
            {dateSigned ? (
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: fontSize,
                        color: 'black'
                    }}
                >
                    {dateSigned}
                </Typography>
            ) : (
                <>
                    <EventNoteIcon
                        sx={{
                            fontSize: iconSize
                        }}
                    />
                    {shouldDisplayLabel && (
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: fontSize,
                                flexGrow: 0.8
                            }}
                        >
                            Date Signed
                        </Typography>
                    )}
                    {isResizable && removeTag && (
                        <IconButton
                            onClick={removeTag}
                            size="small"
                            sx={{ color: 'primary.main' }}
                        >
                            <CloseIcon sx={{ fontSize: fontSize }} />
                        </IconButton>
                    )}
                </>
            )}
        </TagWrapper>
    );
};

export default SignDateTag;
