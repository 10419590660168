import React from 'react';
import { IconButton, TextField, Typography } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloseIcon from '@mui/icons-material/Close';
import { Tag, TAG_ACTIONS } from '../EsignInterface';
import TagWrapper from './TagWrapper';

interface TextfieldTagProps {
    action: string;
    tag?: any;
    fontSize?: string;
    iconSize?: string;
    hasLabel?: boolean;
    removeTag?: () => void;
    setTags?: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const TextfieldTag = ({
    action,
    tag,
    fontSize,
    iconSize,
    hasLabel,
    removeTag,
    setTags
}: TextfieldTagProps) => {
    const isFillable = action === TAG_ACTIONS.FILL;
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;
    const shouldDisplayLabel =
        isViewOnly || isFillable || (isResizable && hasLabel);

    const dragStartHandler = (event) => {
        const rect = event.target.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', 'textfield');
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());
    };

    const handleTextFieldChange = (e) => {
        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.id === tag.id) {
                    return { ...t, inputData: e.target.value };
                }
                return t;
            });
        });
    };

    return (
        <TagWrapper
            isViewOnly={isViewOnly}
            isResizable={isResizable}
            dragStartHandler={dragStartHandler}
        >
            {isFillable ? (
                <TextField
                    placeholder="Text"
                    fullWidth
                    variant="standard"
                    sx={{
                        fontSize: fontSize,
                        flexGrow: 1,
                        // input data always stay at the left and bottom
                        position: 'absolute',
                        left: 2,
                        bottom: 0,
                        '& .MuiInput-underline:before': {
                            // Normal state
                            borderBottom: 'none'
                        },
                        '& .MuiInput-underline:hover:before': {
                            // Hover state - override if needed
                            borderBottom: 'none !important' // Use important to override any other styles on hover
                        },
                        '& .MuiInput-underline:after': {
                            // Focused state
                            borderBottom: 'none'
                        }
                    }}
                    onChange={handleTextFieldChange}
                />
            ) : (
                <>
                    <TextFieldsIcon
                        sx={{
                            fontSize: iconSize
                        }}
                    />
                    {shouldDisplayLabel && (
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: fontSize,
                                flexGrow: 0.8
                            }}
                        >
                            Text
                        </Typography>
                    )}
                    {isResizable && removeTag && (
                        <IconButton
                            onClick={removeTag}
                            size="small"
                            sx={{
                                color: 'primary.main'
                            }}
                        >
                            <CloseIcon sx={{ fontSize: fontSize }} />
                        </IconButton>
                    )}
                </>
            )}
        </TagWrapper>
    );
};

export default TextfieldTag;
