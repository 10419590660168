export const TAG_ACTIONS = {
    VIEW: 'VIEW',
    MODIFY: 'MODIFY',
    FILL: 'FILL'
};

export const TAG_TYPES = {
    SIGNATURE: 'signature',
    TEXTFIELD: 'textfield',
    DATESIGNED: 'dateSigned'
};

export const DOCUMENT_STATUS = {
    WAITINGIGNATURE: 'waitingSignature',
    SIGNED: 'signed'
};

export interface RequiredField {
    id: number;
    name: string;
    ref: React.MutableRefObject<any>;
    required: boolean;
    signed: boolean;
    pageNo: number;
    top: string;
    left: string;
}

export interface Signer {
    externalId: string;
    fullName: string;
    email: string;
}

export interface CreateSignerFormInput {
    fullName: string;
    email: string;
}

export interface Tag {
    id?: number;
    DocumentId: string; // document externalId
    SignerId: string; // signer externalId
    type: string;
    page: number;
    x: number;
    y: number;
    width: number;
    height: number;
    originalCanvasWidth: number;
    originalCanvasHeight: number;
    inputData?: string;
}
