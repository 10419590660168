import React, { useState, useEffect, useRef } from 'react';
import { getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from '@mui/material';
import PageWrapper from '../../global/PageWrapper';
import FileUpload from './FileUpload';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import api from '../../../../api';
import { formateFileName } from '../../global/logic/Formatters';
import { DOCUMENT_STATUS, Tag, TAG_ACTIONS } from '../EsignInterface';
import EsignTagBar from './EsignTagBar';
import ResizableTag from './ResizableTag';
import HandleEmailSigner from '../logic/HandleEmailSigner';

// each signature field should have a page number and position

interface UploadAndTagProps {
    signer: any;
    showSnackbar: showSnackbar;
}

const UploadAndTag = ({ signer, showSnackbar }: UploadAndTagProps) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [documentId, setDocumentId] = useState(null);

    const [fileType, setFileType] = useState(null);
    const canvasRefs = useRef([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const handleFileUpload = (file) => {
        if (
            file.type !== 'application/pdf' &&
            file.type !== 'image/png' &&
            file.type !== 'image/jpeg' &&
            file.type !== 'image/jpg'
        ) {
            showSnackbar(
                `Unsupported file type ${file.type}`,
                // 'PDF, PNG, or JPG (Max. File Size: 30MB)',
                'PDF (Max. File Size: 30MB)',
                'error'
            );
            return;
        }

        // Set file type
        if (file.type === 'application/pdf') {
            setFileType('pdf');
        } else {
            setFileType('image');
        }

        // Prepare FormData
        const formData = new FormData();
        const formattedFileName = formateFileName(file.name);

        formData.append('file', file, formattedFileName);
        formData.append('folder', 'esign');
        formData.append('signerId', signer?.externalId);

        api.post('/newDocument', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'file-type': file.type
            }
        }).then((response) => {
            const { signedUrl, documentId } = response.data;
            setUploadedFile(signedUrl);
            setDocumentId(documentId);
        });
    };

    useEffect(() => {
        if (uploadedFile) {
            if (fileType === 'pdf') {
                const loadingTask = getDocument(uploadedFile);
                loadingTask.promise.then((pdf) => {
                    const numPages = pdf.numPages;
                    setNumberOfPages(numPages);

                    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                        pdf.getPage(pageNum).then((page) => {
                            const viewport = page.getViewport({ scale: 2 });
                            const canvas = canvasRefs.current[pageNum - 1];

                            if (canvas) {
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                const renderContext = {
                                    canvasContext: canvas.getContext('2d'),
                                    viewport: viewport
                                };

                                // if (canvas.renderTask) {
                                //     canvas.renderTask.cancel();
                                // }

                                page.render(renderContext);
                            } else {
                                console.log(
                                    'Canvas is not defined at index:',
                                    pageNum - 1
                                );
                            }
                        });
                    }
                });
            } else if (fileType === 'image') {
                const canvas = canvasRefs.current[0]; // Assuming only one canvas for image
                if (canvas) {
                    const context = canvas.getContext('2d');
                    const img = new Image();
                    img.onload = () => {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        context.drawImage(img, 0, 0, img.width, img.height);
                    };
                    img.src = uploadedFile;
                }
                setNumberOfPages(1); // because image can only have one page
            } else {
                showSnackbar(
                    'Unsupported file type',
                    // 'PDF, PNG, or JPG (Max. File Size: 30MB)',
                    'PDF (Max. File Size: 30MB)',
                    'error'
                );
            }
        }
    }, [uploadedFile]);

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e, pageNo) => {
        e.preventDefault();

        const canvas = e.target;
        const rect = canvas.getBoundingClientRect();

        const type = e.dataTransfer.getData('type');

        // retrive the offset of the mouse cursor from the top left corner
        const offsetX = parseInt(e.dataTransfer.getData('offsetX'), 10);
        const offsetY = parseInt(e.dataTransfer.getData('offsetY'), 10);

        // retrive the width and height of the signature field
        const width = parseInt(e.dataTransfer.getData('width'), 10);
        const height = parseInt(e.dataTransfer.getData('height'), 10);

        // calculate the new position of the signature: mouse cursor position - offset

        const x = e.clientX - rect.left - offsetX;
        const y = e.clientY - rect.top - offsetY;

        setTags((prevTags) => [
            ...prevTags,
            {
                DocumentId: documentId,
                SignerId: signer?.externalId,
                type: type,
                page: pageNo,
                x: x,
                y: y,
                width: width,
                height: height,
                originalCanvasWidth: canvas.width,
                originalCanvasHeight: canvas.height
            }
        ]);
    };

    const baseURL = window.location.origin;

    // append the file name, signature details (page number and position) to the url
    let customerSignUrl =
        baseURL +
        `/esign/test/?document=${documentId}&signer=${signer?.externalId}`;

    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const handleClickFinishAndSend = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSendEmail = async () => {
        await HandleEmailSigner({
            customerSignUrl,
            documentId,
            tags,
            newStatus: DOCUMENT_STATUS.WAITINGIGNATURE,
            showSnackbar
        });

        setOpenEmailDialog(false);
    };

    return (
        <PageWrapper>
            {!uploadedFile ? (
                <FileUpload onFileSelect={handleFileUpload} />
            ) : (
                <Grid container spacing={2}>
                    <EsignTagBar
                        handleClickFinishAndSend={handleClickFinishAndSend}
                    />
                    {/* // display all pdf canvas    */}
                    {fileType === 'pdf' ? (
                        Array.from({ length: numberOfPages }, (_, pageNo) => (
                            <Grid item xs={12} style={{ position: 'relative' }}>
                                <Box
                                    className="document-container"
                                    onDragOver={onDragOver}
                                    onDrop={(e) => onDrop(e, pageNo)}
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: 'auto'
                                    }}
                                >
                                    <canvas
                                        key={pageNo}
                                        ref={(el) =>
                                            (canvasRefs.current[pageNo] = el)
                                        }
                                        className="pdf-canvas"
                                    ></canvas>

                                    {/* // display signature on specific page and position */}
                                    {tags.map((tag, index) => {
                                        if (tag.page === pageNo) {
                                            // Calculate the scaling factor based on the current and original canvas sizes.
                                            const scaleX =
                                                canvasRefs.current[pageNo]
                                                    .width /
                                                tag.originalCanvasWidth;
                                            const scaleY =
                                                canvasRefs.current[pageNo]
                                                    .height /
                                                tag.originalCanvasHeight;

                                            // Adjust position and size.
                                            const adjustedPosition = {
                                                x: tag.x * scaleX,
                                                y: tag.y * scaleY,
                                                width: tag.width * scaleX,
                                                height: tag.height * scaleY
                                            };

                                            return (
                                                <ResizableTag
                                                    key={index}
                                                    index={index}
                                                    action={TAG_ACTIONS.MODIFY}
                                                    tag={tag}
                                                    tags={tags}
                                                    setTags={setTags}
                                                    width={
                                                        adjustedPosition.width
                                                    }
                                                    height={
                                                        adjustedPosition.height
                                                    }
                                                    x={adjustedPosition.x}
                                                    y={adjustedPosition.y}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Box
                                className="pdf-container"
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, 0)}
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 'auto'
                                }}
                            >
                                <canvas
                                    ref={(el) => (canvasRefs.current[0] = el)}
                                    className="image-canvas"
                                ></canvas>
                            </Box>
                        </Grid>
                    )}
                    <Dialog open={openEmailDialog}>
                        <DialogTitle>Email Customer</DialogTitle>
                        <DialogContent>
                            An email will be sent to <b>{signer?.fullName}</b>{' '}
                            at the following email address:{' '}
                            <b>{signer?.email}</b> <br />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEmailDialog(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleClickSendEmail}
                            >
                                Send Email
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            )}
        </PageWrapper>
    );
};

export default UploadAndTag;
