import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Grid, TextField } from '@mui/material';
import { UpsertSigner } from '../logic/HandleSigner';
import { CreateSignerFormInput, Signer } from '../EsignInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

const validateSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup
        .string()
        .email('Invalid Email Address')
        .required('Email is required')
});

interface AddSignerProps {
    signer: Signer | null;
    setSigner: React.Dispatch<React.SetStateAction<Signer>>;
    handleNext: () => void;
    showSnackbar: showSnackbar;
}

const AddSigner = ({
    signer,
    setSigner,
    handleNext,
    showSnackbar
}: AddSignerProps) => {
    const defaultValues = {
        ...signer
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<CreateSignerFormInput>({
        defaultValues: defaultValues,
        resolver: yupResolver<CreateSignerFormInput>(validateSchema) as any,
        mode: 'onTouched'
    });

    const onSubmit = (data: CreateSignerFormInput) => {
        UpsertSigner({
            formData: data,
            setSigner: setSigner,
            handleNext: handleNext,
            showSnackbar: showSnackbar
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    {...register('fullName')}
                    label="Full Name"
                    fullWidth
                    error={!!errors.fullName}
                    helperText={errors.fullName?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...register('email')}
                    label="Email"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleSubmit(onSubmit)} variant="contained">
                    Save and Continue
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddSigner;
