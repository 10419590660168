import { Box, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface FileUploadProps {
    onFileSelect: (file: any) => void;
}

const FileUpload = ({ onFileSelect }: FileUploadProps) => {
    const handleChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            onFileSelect(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // this prevent file from being opened in the browser
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            onFileSelect(file);
        }
    };

    return (
        <Box
            className="upload-container"
            style={{
                width: '80%',
                margin: 'auto',
                padding: '12px'
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <form id="form-file-upload">
                <label htmlFor="input-file-upload">
                    <input
                        // accept=".pdf,.png,.jpeg,.jpg"
                        accept=".pdf"
                        id="input-file-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleChange}
                    />
                    <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderStyle: 'dashed',
                            borderColor: 'grey.300',
                            color: 'grey.500',
                            '&:hover': {
                                borderColor: 'grey.400'
                            }
                        }}
                    >
                        <CloudUploadIcon sx={{ fontSize: 60 }} />
                        <strong>Click to upload</strong> or drag and drop
                        <span style={{ fontSize: 12 }}>
                            {/* PDF, PNG, or JPG (Max. File Size: 30MB) */}
                            PDF (Max. File Size: 30MB),
                        </span>
                    </Button>
                </label>
            </form>
        </Box>
    );
};

export default FileUpload;
