import dayjs from 'dayjs';

var moment = require('moment');
moment().format();

/**
 * CurrencyFormatter
 * Converts a given number to USD structure, rounded to two decimal places (e.g. {$0.00})
 * @author Estienne
 * @param price a given number (e.g. {4.9932})
 * @returns the given number in USD structure
 */
export function CurrencyFormatter(price) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    if (!parseFloat(price)) price = 0;
    return formatter.format(price);
}

/**
 * DateFormatter
 * Converts a timestamp to a readable date format DD/MM/YYYY
 * @author Sienna
 * @param timestamp the timestamp to be formatted
 * @returns the timestamp in a readable date format
 */
export function DateFormatter(timestamp: string) {
    const currentTimestamp = timestamp;
    const momentTimestamp = moment(currentTimestamp);
    return momentTimestamp.format('DD/MM/YYYY');
}

export function DateSafeFormatter(timestamp: string | null) {
    if (timestamp === null) {
        return '';
    }
    const momentTimestamp = moment(timestamp);
    return momentTimestamp.isValid()
        ? momentTimestamp.format('DD/MM/YYYY')
        : 'Invalid date';
}

export function MonthYearFormatter(timestamp: string | null) {
    if (timestamp === null) {
        return '';
    }
    const momentTimestamp = moment(timestamp);
    return momentTimestamp.isValid()
        ? momentTimestamp.format('MM/YYYY')
        : 'Invalid date';
}

export function FileDateFormatter(timestamp: string) {
    const currentTimestamp = timestamp;
    const momentTimestamp = moment(currentTimestamp);
    return momentTimestamp.format('DD-MM-YYYY');
}

export function TimestampFormatter(timestamp: string) {
    if (!timestamp) return '';
    return new Date(timestamp).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}

export function YearFormatter(year: any) {
    if (typeof year === 'string') {
        // Parse the string to a dayjs object and format it
        return dayjs(year).format('YYYY');
    }

    if (year && typeof year.format === 'function') {
        // Use the format method to get the year as a string
        return year.format('YYYY');
    }

    // If vehicleYear is neither a string nor a dayjs object, or does not have a .format() function
    return undefined;
}

/**
 * TitleCaseFormatter
 * Converts a string to title case
 * @author Sienna
 * @param value the string to be converted
 * @returns the given string in title case
 */
export function TitleCaseFormatter(value: string) {
    let formattedValue = value.toLowerCase().split(' ');
    let returnValue = '';

    formattedValue.forEach((item) => {
        if (item.length > 0) {
            returnValue += item[0].toUpperCase() + item.slice(1) + ' ';
        }
    });

    return returnValue;
}

/**
 * ToFixed
 * Round a float to a maximum of 2 decimal points.
 * @author Pierre
 * @param value the float to round
 * @returns the rounded float
 */
export function ToFixed(value: number) {
    // Adding Number.EPSILON allows for correct rounding
    // when a float ends with a 5 (1.255 should return 1.26, not 1.25)
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

/**
 * Convert a given text to camel case (e.g. 'camelCase')
 * @author Estienne
 * @param value the string to be converted
 * @returns the given string in camel case format
 */
export function ToCamelCase(value: string) {
    let splitString = value.split(' ');
    let camelString = splitString[0].toLowerCase();

    for (let i = 1; i < splitString.length; i++) {
        let temp = splitString[i].toLowerCase();
        temp = temp.charAt(0).toUpperCase() + temp.slice(1);
        camelString += temp;
    }

    return camelString;
}

export function addSpacingToText(text, spacesToAdd) {
    // Join each character in string with specified number of spaces
    return text.split('').join(' '.repeat(spacesToAdd));
}


// replace special characters in file name with underscores
export function formateFileName(fileName: string) {
    const specialCharacters = [
        ' ',
        '%',
        '@',
        '/',
        '\\',
        '!',
        '"',
        '#',
        '$',
        '&',
        "'",
        '*',
        '+',
        ',',
        ':',
        ';',
        '<',
        '>',
        '=',
        '?',
        '^',
        '`',
        '{',
        '}',
        '~'
    ];

    // Replace special characters with underscores
    const cleanedFileName = fileName.replaceAll(new RegExp('[' + specialCharacters.join('') + ']', 'g'), '_');

    // Get the file extension
    const extension = cleanedFileName.substring(
        cleanedFileName.lastIndexOf('.'),
        cleanedFileName.length
    );
    const baseName = cleanedFileName.substring(0, cleanedFileName.lastIndexOf('.'));

    // add a timestamp to the file name
    const timestamp = dayjs().format('YYYYMMDDHHmmss');

    // Add a timestamp to the file name, so when upload to S3, it will not overwrite files with the same name
    const formattedFileName = `${baseName}_${timestamp}${extension}`;
    return formattedFileName;

}