import { Box } from '@mui/material';
import { Tag, TAG_ACTIONS, TAG_TYPES } from '../EsignInterface';
import { ResizableBox } from 'react-resizable';
import { useEffect, useState } from 'react';
import TextfieldTag from './TextfieldTag';
import SignTag from './SignTag';
import SignDateTag from './SignDateTag';

// do not remove
import 'react-resizable/css/styles.css';

interface ResizableTagProps {
    index: number;
    action: string;
    tag: Tag;
    tags: Tag[];
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
    width: number;
    height: number;
    x: number;
    y: number;
    currentTagIndex?: number;
    setCurrentTagIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const ResizableTag = ({
    index,
    action,
    tag,
    tags,
    setTags,
    width,
    height,
    x,
    y,
    currentTagIndex
}: ResizableTagProps) => {
    const [size, setSize] = useState({ width, height });
    const iconSize = '1.5 em';
    const fontSize = '0.8em';
    const [hasLabel, setHasLabel] = useState(true);

    // handle resize tag on canvas
    const onResizeStop = (e, data) => {
        const newSize = { width: data.size.width, height: data.size.height };
        setSize(newSize);

        // Update the tag's size in the tags array
        setTags((prevTags) => {
            const updatedTags = [...prevTags];
            updatedTags[index].width = newSize.width;
            updatedTags[index].height = newSize.height;
            return updatedTags;
        });

        // If the new width is smaller than the original width, hide the label, else show it
        if (newSize.width < width) {
            setHasLabel(false);
        } else {
            setHasLabel(true);
        }
    };

    const removeTag = () => {
        setTags((prevTags) => prevTags.filter((_, idx) => idx !== index));
    };

    return (
        <Box
            sx={{
                width: `${size.width}px`,
                height: `${size.height}px`,
                position: 'absolute',
                left: `${x}px`,
                top: `${y}px`
            }}
        >
            <ResizableBox
                width={size.width}
                height={size.height}
                onResizeStop={(e, data) => onResizeStop(e, data)}
                // can resize from the bottom right corner only
                resizeHandles={action === TAG_ACTIONS.MODIFY ? ['se'] : []}
            >
                {tag.type === TAG_TYPES.SIGNATURE ? (
                    <SignTag
                        action={action}
                        tag={tag}
                        fontSize={fontSize}
                        iconSize={iconSize}
                        hasLabel={hasLabel}
                        removeTag={removeTag}
                        setTags={setTags}
                    />
                ) : tag.type === TAG_TYPES.TEXTFIELD ? (
                    <TextfieldTag
                        action={action}
                        tag={tag}
                        fontSize={fontSize}
                        iconSize={iconSize}
                        hasLabel={hasLabel}
                        removeTag={removeTag}
                        setTags={setTags}
                    />
                ) : tag.type === TAG_TYPES.DATESIGNED ? (
                    <SignDateTag
                        action={action}
                        tag={tag}
                        fontSize={fontSize}
                        iconSize={iconSize}
                        hasLabel={hasLabel}
                        removeTag={removeTag}
                        setTags={setTags}
                    />
                ) : null}
            </ResizableBox>
        </Box>
    );
};

export default ResizableTag;
