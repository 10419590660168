import api from '../../../../api';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { CreateSignerFormInput, Signer } from '../EsignInterface';

interface UpsertSignerProps {
    formData: CreateSignerFormInput;
    setSigner: React.Dispatch<React.SetStateAction<Signer>>;
    handleNext: () => void;
    showSnackbar: showSnackbar;
}

export const UpsertSigner = async ({
    formData,
    setSigner,
    handleNext,
    showSnackbar
}: UpsertSignerProps) => {
    try {
        const response = await api.post('/upsertSigner', formData);
        setSigner({
            externalId: response.data.externalId,
            fullName: response.data.fullName,
            email: response.data.email
        });

        handleNext();
        return response;
    } catch (error) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );

        return error;
    }
};
